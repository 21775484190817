import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { allBWICSActions as actions, controlPanelActions, sellerBiddingActions } from '../../actions';
import { pushHistoryActions } from '../../actions/entities/push-history.actions';
import { onBoardingTooltipConst, roles } from '../../constants';
import { useActiveInventorySecuritesIds } from '../../effects/useActiveInventorySecuritesIds';
import { useAllToAllAgreementBlocked } from '../../effects/useAllToAllAgreementBlocked';
import { useMyBwicsExist } from '../../effects/useMyBwicsExist';
import IconSVG from '../../styles/svg-icons';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { BwicSearchResult } from '../../types/bwic-monitor/BwicSearchResult';
import { BwicFilterType } from '../../types/filters/FilterState';
import { PageConfigType } from '../../types/page-config/PageConfigType';
import { AppState } from '../../types/state/AppState';
import { user } from '../../user';
import { isRequestFailed, isRequesting, isRequestNone, isRequestSuccess } from '../../utils';
import { ShowFor } from '../access';
import { ActionBlocker } from '../access/ActionBlocker';
import { FeatureButton } from '../access/FeatureButton';
import { CancelReasonPopup } from '../bidding/seller/CancelReasonPopup';
import { Preloader, Relative } from '../common';
import { BwicColumnCustomizer } from '../common/pageConfig/column-customizer/BwicColumnCustomizer';
import { usePageConfig } from '../common/pageConfig/usePageConfig';
import { SecuritiesSearchInput } from '../filters';
import { useManagersFilterData } from '../filters/dinamicFilterEffects/useManagersFilterData';
import { useTrusteeFilterData } from '../filters/dinamicFilterEffects/useTrusteeFilterData';
import { OnBoardingTooltip } from '../onBoarding/OnBoardingTooltip';
import { BwicMonitorAlertsPreferences } from '../profile/tabs/email-preferences/tabs/bwic-monitor/BwicMonitorAlertsPreferences';
import { RequestSettlementAgentAgreementBanner } from '../request-settlement-agent-agreement/RequestSettlementAgentAgreementBanner';
import { AllBwicsFilter, getFilterType } from './AllBwicsFilter';
import { AllBwicsTab } from './AllBwicsTab';
import { AllBwicsTabList } from './AllBwicsTabList';
import { BwicGroupView } from './BwicGroupView';
import { BwicResultPlaceholder } from './BwicsResultExist';
import { Stats } from './Stats';
import { SortDropdown } from '../common/SortDropdown';
import { BwicMonitorSortByOptions, bwicMonitorSortByOptionsText } from '../../types/state/AllBwicsState';
import { AllBwicsSummaryRow } from './AllBwicsSummaryRow';
import { appConfig } from '../../app-config';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { useLocation } from 'react-router';

interface AllBwicsProps {
    myBwicsOnly: boolean;
    isAllToAll?: boolean;
    activeTab: AllBwicsTab;
}

export function AllBwics({ myBwicsOnly, isAllToAll, activeTab = AllBwicsTab.All }: AllBwicsProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const pageConfigType = activeTab === AllBwicsTab.All ? PageConfigType.BwicMonitorAll : undefined;
    const isPageConfigSupported = !!pageConfigType;
    const {
        config, requestState: pageConfigRequestState, defaultConfigRequestState
    } = usePageConfig(pageConfigType, !isPageConfigSupported, BwicFilterType.BwicMonitor);
    const searchTermItems = useSelector((state: AppState) => state.searchSecurities.searchTermItems || []);
    const sortBy = useSelector((state: AppState) => state.allBWICS.sortBy);
    const allToAllState = useSelector((state: AppState) => state.allBWICS.isAllToAll);
    const searchInProgress = useSelector((state: AppState) => state.allBWICS.searchInProgress);
    const isNextPageRequesting = useSelector((state: AppState) => state.allBWICS.isNextPageRequesting);
    const bwics = useSelector((state: AppState) => state.allBWICS.bwics || []);
    const isRequestingExportBwics = useSelector((state: AppState) => state.allBWICS.isRequestingExportBwics);
    const myBwicsOnlyState = useSelector((state: AppState) => state.allBWICS.myBwicsOnly);
    const [activeInvenotryRequestState, activeInventorySecurityIdByCount] =
        useActiveInventorySecuritesIds(user.hasSingleRole(roles.SellerAdministrator));
    const [myBwicsExist, requestStateIsMyBwicsExist] = useMyBwicsExist(activeTab, myBwicsOnly, isAllToAll);
    const managersRequestState = useManagersFilterData(getFilterType(activeTab));
    const trusteeRequestState = useTrusteeFilterData(getFilterType(activeTab));

    const [cancelBwicDialogVisible, setCancelBwicDialogVisible] = useState(false);
    const [bwic, setBwic] = useState<BwicSearchResult | null>(null);

    const allToAllAgreementBlocked = useAllToAllAgreementBlocked();
    const isAllToAllWithoutAgreement = isAllToAll && allToAllAgreementBlocked;
    const isFilterDisabled = (myBwicsOnly ? (!myBwicsExist || isNextPageRequesting) : isNextPageRequesting) || isAllToAllWithoutAgreement;

    const isPageConfigLoaded = isRequestSuccess(pageConfigRequestState, defaultConfigRequestState)
        || isRequestFailed(pageConfigRequestState, defaultConfigRequestState);
    const isColumnCustomizerDisabled = !isPageConfigSupported
        || !isRequestSuccess(pageConfigRequestState, defaultConfigRequestState);

    const hideSettlementAgentAgreementBanner = activeTab === AllBwicsTab.Sell && user.hasRoles(...roles.seller());

    useEffect(() => {
        if (
            (isPageConfigLoaded || !isPageConfigSupported) &&
            (isRequestSuccess(managersRequestState) || isRequestFailed(managersRequestState)) &&
            (isRequestSuccess(trusteeRequestState) || isRequestFailed(trusteeRequestState))
        ) {
            dispatch(actions.init(myBwicsOnly, isAllToAll, location.search, activeTab));
        }
        return () => {
            dispatch(actions.reset());
            dispatch(controlPanelActions.hide());
            dispatch(pushHistoryActions.reset());
        }; // eslint-disable-next-line
    }, [myBwicsOnly, isAllToAll, managersRequestState, trusteeRequestState, dispatch, isPageConfigLoaded]);

    const showCancelBwicDialog = useCallback((bwic: BwicSearchResult) => {
        setBwic(bwic);
        setCancelBwicDialogVisible(true);
    }, []);

    const closeCancelBwicDialog = () => {
        setBwic(null);
        setCancelBwicDialogVisible(false);
    };

    const handleBwicCancel = (reason: string) => {
        if (bwic) {
            dispatch(sellerBiddingActions.cancelBwic(bwic.referenceName, reason));
            closeCancelBwicDialog();
        }
    };

    const isLoading =
        Boolean(myBwicsOnly) !== Boolean(myBwicsOnlyState) ||
        Boolean(isAllToAll) !== Boolean(allToAllState) ||
        isRequestNone(searchInProgress, managersRequestState, trusteeRequestState) ||
        isRequesting(searchInProgress, activeInvenotryRequestState, managersRequestState, trusteeRequestState) ||
        (!isPageConfigLoaded && isPageConfigSupported);

    return (
        <div className="container">
            <div className="sub-header sub-header-pipeline">
                <div className="sub-header-row type01 flex-row">
                    <ShowFor roles={[...roles.seller(), ...roles.bd(), roles.SellerAdministrator]}>
                        <AllBwicsTabList activeTab={activeTab} />
                    </ShowFor>
                    <ShowFor roles={[...roles.admin(), roles.DataEntry, roles.Media, roles.Viewer]}>
                        <h1>All BWICs</h1>
                    </ShowFor>
                    <Relative>
                        <SecuritiesSearchInput
                            onSearch={() => dispatch(actions.searchBwics(activeTab, false, true))}
                            disabled={isLoading || (!bwics.length && !searchTermItems.length) || isNextPageRequesting || isFilterDisabled}
                            requiredFeature={SubscriptionFeature.BwicMonitorSecuritySearch}
                        />
                        <OnBoardingTooltip page={onBoardingTooltipConst.pages.allBwics} step={2} hidden={isLoading} />
                    </Relative>
                    <div className="flex-item-right controls flex-row">
                        {!isAllToAll && <ShowFor roles={[...roles.bd(), ...roles.seller(), roles.SellerAdministrator]}>
                            <Stats />
                        </ShowFor>}
                        <BwicMonitorAlertsPreferences />
                        <ActionBlocker feature={SubscriptionFeature.exportAllBwics}>
                            <Preloader
                                small={true}
                                fullScreen={false}
                                inProgress={isRequestingExportBwics}
                                text="Exporting…"
                            >
                                <FeatureButton
                                    className="btn btn-link"
                                    text="Export All"
                                    disabled={!bwics.length || isNextPageRequesting || isLoading || isFilterDisabled}
                                    onClick={() => dispatch(actions.exportBwics(activeTab))}
                                >
                                    <IconSVG name="export" width={16} height={16} />
                                </FeatureButton>
                            </Preloader>
                        </ActionBlocker>
                    </div>
                </div>
                <div className="sub-header-row type02 flex-row align-items-flex-start row-align-filters">
                    <AllBwicsFilter
                        tab={activeTab}
                        disabled={isFilterDisabled || isLoading}
                        myBwicsExist={myBwicsExist}
                        requestStateIsMyBwicsExist={requestStateIsMyBwicsExist}
                    />
                    <ActionBlocker feature={SubscriptionFeature.BwicMonitorSorting}>
                        {
                            blocked =>
                                <SortDropdown
                                    disabled={!bwics.length || isLoading || blocked}
                                    items={Object.values(BwicMonitorSortByOptions).map(value => ({
                                        value,
                                        text: bwicMonitorSortByOptionsText[value],
                                        selected: sortBy === value,
                                    }))}
                                    onChange={(sortBy) => dispatch(actions.sortChanged(sortBy, activeTab))}
                                />

                        }
                    </ActionBlocker>
                    {isPageConfigSupported && <div className="flex-row">
                        <div className="vertical-divider margin-b-0" />
                        <BwicColumnCustomizer
                            tabs={{ 'All': PageConfigType.BwicMonitorAll }}
                            disabled={isColumnCustomizerDisabled || isLoading}
                            defaultTab={pageConfigType}
                            requiredFeature={SubscriptionFeature.BwicMonitorColumnCustomizer}
                        />
                    </div>}
                </div>
                <ShowFor roles={[...roles.seller(), ...roles.bd(), roles.SellerAdministrator]}>
                    {hideSettlementAgentAgreementBanner ? null : <RequestSettlementAgentAgreementBanner />}
                </ShowFor>
            </div>
            {appConfig.isSummaryRowHidden ? null: <AllBwicsSummaryRow tab={activeTab} />}
            <div className={
                cn('container-bwics container-flex container-tabs flex-row', {
                    'all-tab': !myBwicsOnlyState,
                    'sell-tab': user.hasRoles(...roles.seller()) && myBwicsOnlyState,
                    'all-admin-tab': user.hasRoles(...roles.admin()),
                    'all-viewer-tab': user.hasRoles(roles.Viewer)
                })}
            >
                <BwicResultPlaceholder
                    myBwicsExist={Boolean(myBwicsExist)}
                    requestStateIsMyBwicsExist={requestStateIsMyBwicsExist}
                    isLoading={isLoading}
                    activeTab={activeTab}>
                    <BwicGroupView
                        pageConfig={config}
                        activeTab={activeTab}
                        onCancel={showCancelBwicDialog}
                        activeInventorySecurityIdByCount={activeInventorySecurityIdByCount}
                    />
                </BwicResultPlaceholder>
            </div>
            {
                cancelBwicDialogVisible &&
                <CancelReasonPopup
                    onClose={closeCancelBwicDialog}
                    onCancel={handleBwicCancel}
                />
            }
        </div>
    );
}
