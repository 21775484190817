import { roles, routes } from "../../constants";
import { RequestState } from "../../constants/request-state";
import { useAllToAllAgreementBlocked } from "../../effects/useAllToAllAgreementBlocked";
import { useAppSelector } from "../../effects/useAppSelector";
import { user } from "../../user";
import { isRequesting, isRequestNone } from "../../utils";
import { BlockedFeatureContent, SubscribeLink } from "../access/BlockedFeatureText";
import { FeatureLink } from "../access/FeatureLink";
import { AllToAllArgeementRequiredActionBlocker } from "../agreements/AllToAllArgeementRequiredActionBlocker";
import { EmptyPlaceholder, Preloader } from "../common";
import { AllBwicsTab } from "./AllBwicsTab";

interface BwicResultPlaceholderProps {
    activeTab: AllBwicsTab;
    children: React.ReactNode;
    isLoading: boolean;
    requestStateIsMyBwicsExist: RequestState;
    myBwicsExist: boolean;
}

const NewBwicButton = () => (
    <AllToAllArgeementRequiredActionBlocker>
        {(disabled => (
            <FeatureLink disabled={disabled} className="btn btn-main" path={routes.newBWIC}>New BWIC</FeatureLink>
        ))}
    </AllToAllArgeementRequiredActionBlocker>
)

export const BwicResultPlaceholder = ({ activeTab, children, isLoading, myBwicsExist, requestStateIsMyBwicsExist }: BwicResultPlaceholderProps) => {
    const adavancedFiltersBlocked = useAppSelector(s => s.allBWICS.advancedFilterBlocked);
    const isAllTab = activeTab === AllBwicsTab.All;

    const allToAllAgreementBlocked = useAllToAllAgreementBlocked();
    const inProgress = isLoading
        || (!isAllTab && (isRequestNone(requestStateIsMyBwicsExist) || isRequesting(requestStateIsMyBwicsExist)))
    const noResults = !isAllTab && !myBwicsExist;

    if (inProgress) {
        return <Preloader inProgress={inProgress} />
    }

    if (adavancedFiltersBlocked) {
        return <BlockedFeatureContent className="empty-placeholder" inline text={<><SubscribeLink /> to see BWIC details.</>} />
    }

    const getPlaceholderProps = () => {
        switch (true) {
            case activeTab === AllBwicsTab.Buy:
                return {
                    title: '',
                    text: 'You do not have any BWICs yet.',
                    children: null

                }
            case user.hasRoles(roles.SellerAdministrator, roles.SellerViewer): {
                return {
                    title: 'No BWICs Scheduled',
                    text: 'You have not scheduled any BWICs.',
                    children: null
                };
            }
            case allToAllAgreementBlocked:
                return {
                    title: '',
                    text: 'There are no created BWICs yet',
                    children: <NewBwicButton />
                }
            default: {
                return {
                    title: 'No BWICs Scheduled',
                    text: <>You have not scheduled any BWICs.<br /> To create a new BWIC, click “New BWIC” below.</>,
                    children: <NewBwicButton />
                }
            }

        }
    }

    if (noResults) {
        const { title, text, children } = getPlaceholderProps();
        return (
            <EmptyPlaceholder title={title} text={text}>
                {children}
            </EmptyPlaceholder>
        )
    }

    return <>{children}</>;
}
