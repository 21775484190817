import { ControlPanel } from '../bidding/common/ControlPanel';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { allBWICSActions } from '../../actions';
import { user } from '../../user';
import { roles } from '../../constants';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';

export function AllBwicsControlPanel() {
    const dispatch = useAppDispatch();

    const activeBwic = useSelector((s: AppState) => s.allBWICS.activeBwicReferenceName
        ? s.allBWICS.bwics?.find(b => b.referenceName === s.allBWICS.activeBwicReferenceName)
        : null);

    return <ControlPanel
        bwic={activeBwic}
        historyEnabled={false}
        showEvalValues={!user.hasRoles(roles.Media)}
        accessDealDocumentsFeature={activeBwic?.isParsed ? SubscriptionFeature.CanAccessDealDocuments : null}
        onClose={() => dispatch(allBWICSActions.setActiveBwic(null))}
    />
}
