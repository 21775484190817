import { onBoardingTooltipConst, roles } from '../../constants';
import { BwicSearchResult } from '../../types/bwic-monitor/BwicSearchResult';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { ShowFor } from '../access';
import { ExportButton } from '../bidding/common';
import { ContextMenu } from '../controls';
import { OnBoardingTooltip } from '../onBoarding/OnBoardingTooltip';
import { AllBwicsTab } from './AllBwicsTab';
import { BwicViewLink } from '../common/BwicViewLink';

interface Props {
    bwic: BwicSearchResult;
    bwicIndex: number;
    activeTab: AllBwicsTab;
    onCancel: (bwic: BwicSearchResult) => void;
}

export function MyBwicCustomButtons({ bwic, bwicIndex, onCancel, activeTab }: Props) {
    return (
        <div className="flex-row justify-content-end" onClick={(e) => e.stopPropagation()}>
            <div className="display-inline" onClick={e => e.stopPropagation()}>
                <BwicViewLink
                    small
                    referenceName={bwic.referenceName}
                    status={bwic.status}
                    isDirectBidding={bwic.isClearingBankParticipant}
                    isAllToAll={bwic.isAllToAll}
                    isMy={bwic.isMy}
                    isParticipant={bwic.isParticipant}
                    isParsed={bwic.isParsed}
                />
                {bwicIndex === 0 && <OnBoardingTooltip page={onBoardingTooltipConst.pages.allBwics} step={4} />}
            </div>
            {
                activeTab === AllBwicsTab.Sell &&
                <div className="action-button-wrap cancel-action">
                    {
                        bwic.status === BwicStatus.scheduled &&
                        <ContextMenu
                            text=""
                            menuItems={[{
                                text: 'Cancel',
                                requiredRoles: [roles.SellerTrader, roles.BrokerDealerTrader],
                                action: () => onCancel(bwic)
                            }]}
                        />
                    }
                </div>
            }
            <ShowFor role={roles.SellerAdministrator}>
                <div className="action-button-wrap">
                    {
                        bwic.status === BwicStatus.finished &&
                        <ExportButton tooltipText="Export BWIC data" bwicReferenceName={bwic.referenceName} textVisible={false} />
                    }
                </div>
            </ShowFor>
        </div>
    );
}
