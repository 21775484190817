import * as React from 'react';
import { urlBuilder } from '../../services/url.builder';
import { BwicSearchResult } from '../../types/bwic-monitor/BwicSearchResult';
import { canBid } from '../../utils/direct-bidding.utils';
import {
    DirectBiddingDisclaimerActionBlocker
} from '../direct-bidding-agreement-disclaimer/DirectBiddingDisclaimerActionBlocker';
import { FeatureButton } from '../access/FeatureButton';
import { TooltipPlacement } from '../common/Tooltip';
import { roles, routes } from '../../constants';
import { useHistory } from 'react-router';
import { BwicPositionSearchResult } from '../../types/bwic-monitor/BwicPositionSearchResult';
import { useAgreement } from '../seller-buyside/useAgreement';
import { OnHoverTooltip } from '../common/OnHoverTooltip';
import { RoleActionBlocker } from '../access/RoleActionBlocker';

interface Props {
    bwic: BwicSearchResult;
    position: BwicPositionSearchResult;
}

export function BuyerBidButton({ bwic, position }: Props) {
    const history = useHistory();
    const { agreement } = useAgreement();

    const handleClick = () => {
        const path = urlBuilder(routes.sellerBuy, { statuses: 'Scheduled,Bidding', isinCusipsAndTickers: encodeURI(position.ticker) });
        history.push(path);
    };

    const canImprove = canBid(bwic.status, bwic.isColorDistribution, false, agreement);

    if (canImprove) {
        return (
            <div className="flex-none data-table-item-buy-btn text-right" onClick={e => e.stopPropagation()}>
                <RoleActionBlocker placement={TooltipPlacement.BottomRight} roles={[roles.SellerTrader]}>
                    {
                        (blocked: boolean) =>
                            <DirectBiddingDisclaimerActionBlocker onConfirm={handleClick}>
                                <OnHoverTooltip disabled={blocked} overlay="Click to open Buy tab">
                                    <FeatureButton
                                        className="btn btn-main btn-sm width-64"
                                        disabled={bwic.isColorDistribution}
                                        onClick={handleClick}
                                    >
                                        Bid
                                    </FeatureButton>
                                </OnHoverTooltip>
                            </DirectBiddingDisclaimerActionBlocker>
                    }
                </RoleActionBlocker>
            </div>
        );
    }

    return null;
}
