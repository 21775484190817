import React from 'react';
import { IColumnDefinition } from '../bidding/common/table/types/ColumnDefinition';
import { ColumnBuilder } from '../bidding/common/table/columns/column-builder/ColumnBuilder';
import { IDealersStats } from '../../types/models/DealersStats';
import { user } from '../../user';
import { roles, SORTING_TYPE, constants } from '../../constants';
import { OnHoverTooltip } from "../common";

export const statsColumns = () => {
    const isSeller = user.hasRoles(...roles.seller());
    const statsColumnDefinitions: IColumnDefinition<IDealersStats>[] = [
        {
            columnKey: 'dealer',
            renderColumnHeaderContent: () => isSeller ? 'Dealer' : 'Client',
            renderColumnContent: position => (
                isSeller ?
                    <OnHoverTooltip overlay={position.dealerName}>
                        <span>{position.dealerCode || ''}</span>
                    </OnHoverTooltip> :
                    <OnHoverTooltip overlay={position.sellerName}>
                        <span>{position.sellerCode || ''}</span>
                    </OnHoverTooltip>
            ),
            headerClassName: 'data-list-ds-dealer',
            bodyClassName: 'data-list-ds-dealer',
            sortingField: isSeller ? 'dealerCode' : 'sellerCode',
            sortingType: SORTING_TYPE.string,
        }, {
            columnKey: 'live-bidding',
            renderColumnHeaderContent: () => 'LiveBidding\u2122',
            headerOnHoverText: '# of your LiveBidding\u2122 BWICs in which this dealer submitted a bid',
            renderColumnContent: position => String(position.liveBiddingNumber),
            headerClassName: 'data-list-ds-live-bidding',
            bodyClassName: 'data-list-ds-live-bidding',
            sortingField: 'liveBiddingNumber',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'bwics',
            renderColumnHeaderContent: () => 'Total BWICs',
            headerOnHoverText: '# of your BWICs in which this dealer submitted a bid',
            renderColumnContent: position => String(position.bwicsNumber),
            headerClassName: 'data-list-ds-bwic',
            bodyClassName: 'data-list-ds-bwic',
            sortingField: 'bwicsNumber',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'invited',
            renderColumnHeaderContent: () => 'Total Invited',
            headerOnHoverText: isSeller ? '# of BWICs you have invited this dealer to' : '# of BWICs Seller has invited this dealer to',
            renderColumnContent: position => String(position.invitedNumber),
            headerClassName: 'data-list-ds-invited',
            bodyClassName: 'data-list-ds-invited',
            sortingField: 'invitedNumber',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'participation-rate',
            renderColumnHeaderContent: () => 'Participation %',
            headerOnHoverText: 'BWICs Joined (including LiveBidding) / BWICs Invited',
            renderColumnContent: position => (position.participationRate * 100).toFixed(2),
            headerClassName: 'data-list-ds-rate',
            bodyClassName: 'data-list-ds-rate',
            sortingField: 'participationRate',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'pxTalk-subm',
            renderColumnHeaderContent: () => 'PxTalk Subm %',
            headerOnHoverText: '% of your BWICs where this dealer has submitted Px Talk',
            renderColumnContent: position => (position.pxTalkSubmissionRate * 100).toFixed(2),
            headerClassName: 'data-list-ds-pxtalk',
            bodyClassName: 'data-list-ds-pxtalk',
            sortingField: 'pxTalkSubmissionRate',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'avg-px-talk-deviation',
            renderColumnHeaderContent: () => 'Avg PxTalk Deviation',
            headerOnHoverText: 'Average deviation of Px Talk vs traded price',
            renderColumnContent: position => (
                <span>{position.avgPxTalkDeviation == null ? constants.emptyPlaceholder : position.avgPxTalkDeviation.toFixed(2)}</span>
            ),
            headerClassName: 'data-list-ds-pxtalk-deviation',
            bodyClassName: 'data-list-ds-pxtalk-deviation',
            sortingField: 'avgPxTalkDeviation',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: '#-of-bids',
            renderColumnHeaderContent: () => 'Bids #',
            headerOnHoverText: 'Total number of bids this dealer has submitted across all your BWICs',
            renderColumnContent: position => String(position.bidsNumber),
            headerClassName: 'data-list-ds-bids',
            bodyClassName: 'data-list-ds-bids',
            sortingField: 'bidsNumber',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'avg-#-of-impv',
            renderColumnHeaderContent: () => 'Avg Impv #',
            headerOnHoverText: 'The average number of improvement bids this dealer submits per bond, not including the initial bid',
            renderColumnContent: position => position.improvementsAvgNumber.toFixed(2),
            headerClassName: 'data-list-ds-impv',
            bodyClassName: 'data-list-ds-impv',
            sortingField: 'improvementsAvgNumber',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'total-trades',
            renderColumnHeaderContent: () => 'Total Trades',
            headerOnHoverText: '# of trades completed with this dealer',
            renderColumnContent: position => String(position.tradesNumber),
            headerClassName: 'data-list-ds-total-trades',
            bodyClassName: 'data-list-ds-total-trades',
            sortingField: 'tradesNumber',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'win-rate',
            renderColumnHeaderContent: () => 'Win %',
            headerOnHoverText: 'Trades to # of bonds',
            renderColumnContent: position => (position.winRate * 100).toFixed(2),
            headerClassName: 'data-list-ds-win-rate',
            bodyClassName: 'data-list-ds-win-rate',
            sortingField: 'winRate',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: '#-of-bonds',
            renderColumnHeaderContent: () => 'Bonds #',
            headerOnHoverText:
                isSeller
                    ? 'The number of securities (non-unique) you have posted that this dealer has bid on'
                    : 'The number of securities (non-unique) Seller has posted that this dealer has bid on',
            renderColumnContent: position => String(position.bondsNumber),
            headerClassName: 'data-list-ds-bonds',
            bodyClassName: 'data-list-ds-bonds',
            sortingField: 'bondsNumber',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'aaa',
            renderColumnHeaderContent: () => 'AAA',
            headerOnHoverText: '# of bonds broken down by rating',
            renderColumnContent: position => String(position.aaa),
            headerClassName: 'data-list-ds-aaa',
            bodyClassName: 'data-list-ds-aaa',
            sortingField: 'aaa',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'aa',
            renderColumnHeaderContent: () => 'AA',
            headerOnHoverText: '# of bonds broken down by rating',
            renderColumnContent: position => String(position.aa),
            headerClassName: 'data-list-ds-aa',
            bodyClassName: 'data-list-ds-aa',
            sortingField: 'aa',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'a',
            renderColumnHeaderContent: () => 'A',
            headerOnHoverText: '# of bonds broken down by rating',
            renderColumnContent: position => String(position.a),
            headerClassName: 'data-list-ds-a',
            bodyClassName: 'data-list-ds-a',
            sortingField: 'a',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'bbb',
            renderColumnHeaderContent: () => 'BBB',
            headerOnHoverText: '# of bonds broken down by rating',
            renderColumnContent: position => String(position.bbb),
            headerClassName: 'data-list-ds-bbb',
            bodyClassName: 'data-list-ds-bbb',
            sortingField: 'bbb',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'bb',
            renderColumnHeaderContent: () => 'BB',
            headerOnHoverText: '# of bonds broken down by rating',
            renderColumnContent: position => String(position.bb),
            headerClassName: 'data-list-ds-bb',
            bodyClassName: 'data-list-ds-bb',
            sortingField: 'bb',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'b',
            renderColumnHeaderContent: () => 'B',
            headerOnHoverText: '# of bonds broken down by rating',
            renderColumnContent: position => String(position.b),
            headerClassName: 'data-list-ds-b',
            bodyClassName: 'data-list-ds-b',
            sortingField: 'b',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'other',
            renderColumnHeaderContent: () => 'Other',
            headerOnHoverText: '# of bonds broken down by rating',
            renderColumnContent: position => String(position.otherRatings),
            headerClassName: 'data-list-ds-other',
            bodyClassName: 'data-list-ds-other',
            sortingField: 'otherRatings',
            sortingType: SORTING_TYPE.number,
        }, {
            columnKey: 'nr',
            renderColumnHeaderContent: () => 'NR',
            headerOnHoverText: '# of bonds broken down by rating',
            renderColumnContent: position => String(position.nr),
            headerClassName: 'data-list-ds-nr',
            bodyClassName: 'data-list-ds-nr',
            sortingField: 'nr',
            sortingType: SORTING_TYPE.number,
        }
    ];
    return statsColumnDefinitions.map(c => {
        return new ColumnBuilder(c);
    })
};
