import * as React from 'react';
import { BwicSearchResult } from '../../types/bwic-monitor/BwicSearchResult';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { isActiveTrade } from '../../types/trades/TradeStatus';
import { moneyUtils } from '../../utils/money.utils';
import IconSVG from '../../styles/svg-icons';
import { SellerBwicPositionSearchResult } from '../../types/bwic-monitor/BwicPositionSearchResult';

interface Props {
    bwic: BwicSearchResult;
}

export function BwicTradedAmount({ bwic }: Props) {
    if (bwic.isParsed || bwic.status !== BwicStatus.finished) return null;

    const tradedAmount = bwic.securities
        .filter(s => isActiveTrade((s as SellerBwicPositionSearchResult).trade))
        .map(s => (s as SellerBwicPositionSearchResult).trade?.size ?? 0)
        .reduce((accumulator, value) => accumulator + value, 0);

    if (!tradedAmount) return null;

    return (
        <span className="bwic-traded-info" data-testid="bwic-traded-amount">
            <IconSVG name="traded" width={16} height={16} />
            Traded: {moneyUtils.amountToMM(tradedAmount, true)}
        </span>
    );
}
