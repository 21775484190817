import * as React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { BwicSearchResult } from '../../types/bwic-monitor/BwicSearchResult';
import { AppState } from '../../types/state/AppState';
import { allBWICSActions, rulesActions } from '../../actions';
import { onBoardingTooltipConst } from '../../constants';
import { OnBoardingTooltip } from '../onBoarding/OnBoardingTooltip';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { BwicRulesPseudoLink } from '../common/BwicRulesPseudoLink';

interface Props {
    bwic: BwicSearchResult;
    rowIndex: number;
    isBrokerDealer: boolean;
}

export function ViewBwicRulesButton({ bwic, rowIndex, isBrokerDealer }: Props) {
    const dispatch = useAppDispatch();

    const bwicRulesVisible = !!useSelector((s: AppState) => s.rules.show)
    const activeBwic = useSelector((s: AppState) => s.allBWICS.activeBwicReferenceName
        ? s.allBWICS.bwics?.find(b => b.referenceName === s.allBWICS.activeBwicReferenceName)
        : null);
    const activePositionId: number | undefined = useSelector((s: AppState) =>
        (s.bwicHistory.visible && s.bwicHistory.positionId) ||
        (s.securityDetails.security && s.securityDetails.security.id) ||
        (s.bwicLog.security && s.bwicLog.security.id) ||
        (s.rules.show && s.rules.positionId) ||
        (s.pxTalkList.visible && s.pxTalkList.positionId)
    );

    const isBwicRulesVisible = bwicRulesVisible && activeBwic && activeBwic.referenceName === bwic.referenceName && !activePositionId;

    const handleRulesClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(allBWICSActions.setActiveBwic(bwic.referenceName));

        if (bwicRulesVisible &&
            activeBwic &&
            activeBwic.referenceName === bwic.referenceName &&
            !activePositionId) {
            dispatch(rulesActions.hide());
            dispatch(allBWICSActions.setActiveBwic(undefined));
        } else {
            dispatch(rulesActions.show(undefined, true));
        }
    }

    return (
        <>

            <BwicRulesPseudoLink
                className={cn({
                    'is-active': isBwicRulesVisible,
                    'text-ellipsis': !bwic.isParsed && isBrokerDealer
                })}
                isParsed={bwic.isParsed}
                onClick={handleRulesClick}
                process={bwic.process}
                parsedProcess={bwic.parsedProcess}
            />
            {
                rowIndex === 0 &&
                <OnBoardingTooltip page={onBoardingTooltipConst.pages.allBwics} step={3} />
            }
        </>
    );

}
