import * as React from 'react';
import { TabItem, TabList } from '../bidding/common/tab-list';
import { roles, routes } from '../../constants';
import { AllBwicsTab } from './AllBwicsTab';
import { ShowFor } from '../access';
import { useSelector } from 'react-redux';
import { AppState } from '../../types/state/AppState';
import { TradedPositionsCountBadge } from '../common/TradedPositionsCountBadge';
import { TradeStatus } from '../../types/trades/TradeStatus';
import { user } from '../../user';

interface Props {
    activeTab?: AllBwicsTab;
}

export function AllBwicsTabList({ activeTab = AllBwicsTab.All }: Props) {
    const tradedPositionsCount = useSelector((state: AppState) => state.tradeConfirm.tradedPositions.filter(p => p.trade.status === TradeStatus.pending).length);
    const isBd = user.hasRoles(...roles.bd());

    return (
        <TabList className="tabs-large">
            <TabItem
                title="All BWICs"
                path={routes.allBWICs}
                disabled={user.hasSingleRole(roles.SellerAdministrator)}
                active={activeTab === AllBwicsTab.All}
            />
            <ShowFor roles={[...roles.seller(), roles.SellerAdministrator, ...roles.bd()]}>
                <TabItem
                    disabled={user.hasSingleRole(roles.SellerAdministrator)}
                    title={<>Buy<TradedPositionsCountBadge tradedPositionsCount={tradedPositionsCount} hideCount={true} /></>}
                    path={isBd ? routes.myBWICs : routes.sellerBuy}
                    active={activeTab === AllBwicsTab.Buy}
                />
            </ShowFor>
            {!user.current()?.isClearingBank && <ShowFor roles={[...roles.seller(), roles.SellerAdministrator, roles.BrokerDealerTrader]}>
                <TabItem
                    title="Sell"
                    path={isBd ? routes.allToAll : routes.sellerSell}
                    active={activeTab === AllBwicsTab.Sell}
                />
            </ShowFor>}
        </TabList>
    );
}
