import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from '../controls';
import { Table } from '../bidding/common/table';
import { statsColumns } from './statsColumns';
import { Preloader, OnHoverTooltip } from '../common';
import IconSVG from '../../styles/svg-icons';
import { roles, SORT } from '../../constants';
import { user } from '../../user';
import { dealersStatsActions } from "../../actions";
import { FeatureButton } from '../access/FeatureButton';
import { RoleActionBlocker } from '../access/RoleActionBlocker';

export function Stats() {
    const dispatch = useDispatch();
    const fetchStatsRequestStatus = useSelector(s => s.dealersStats.fetchStatsRequestStatus);
    const fetchStatsExistRequestState = useSelector(s => s.dealersStats.fetchStatsExistRequestState);
    const exportStatsRequestStatus = useSelector(s => s.dealersStats.exportStatsRequestStatus);
    const stats = useSelector(s => s.dealersStats.stats);
    const statsExist = useSelector(s => s.dealersStats.statsExist);
    const [visible, setVisible] = useState(false);
    const isSeller = user.hasRoles(...roles.seller());
    const isBdTrader = user.hasRoles(roles.BrokerDealerTrader);
    const title = isSeller ? 'Dealers' : 'Clients';


    useEffect(() => {
        if (isSeller || isBdTrader) {
            dispatch(dealersStatsActions.checkStatsExist());
        }
    }, [isSeller, isBdTrader, dispatch]);

    useEffect(() => {
        if (visible) {
            dispatch(dealersStatsActions.fetchStats());
        }
    }, [visible, dispatch]);

    const handleClose = (e) => {
        e.stopPropagation();
        setVisible(false);
    };

    const handleSetVisible = (e) => {
        e.stopPropagation();
        setVisible(true);
    };

    const handleExportDealersStats = (e) => {
        e.stopPropagation();
        dispatch(dealersStatsActions.exportDealersStats());
    };

    const renderPopupTitle = () => {
        return (
            <div className="flex-row">
                <h1>{title} Stats</h1>
                <button
                    className="btn btn-link flex-item-right"
                    disabled={exportStatsRequestStatus || fetchStatsRequestStatus}
                    onClick={handleExportDealersStats}
                >
                    <IconSVG name="export" width={16} height={16} />Export
                </button>
            </div>
        );
    };

    const statsCodeToUpCase = (stats, key) => {
        return stats.map(stat => (
            {
                ...stat,
                [key]: stat[key] && stat[key].toUpperCase(),
            }
        )
        )
    }

    const changedStats = isSeller
        ? statsCodeToUpCase(stats, 'dealerCode')
        : statsCodeToUpCase(stats, 'sellerCode');

    const renderPopup = () => {
        return (visible &&
            <Popup
                modalClass="modal-dealer-stats"
                onClose={handleClose}
                renderTitle={renderPopupTitle()}
                renderInBody
            >
                <div className="modal-body">
                    <Preloader inProgress={fetchStatsRequestStatus}>
                        <ul className="dealer-stats-groups-name">
                            <li>BWICs Participation</li>
                            <li>Bidding Stats</li>
                            <li>Bonds by Rating</li>
                        </ul>
                        <Table
                            dataItems={changedStats}
                            columns={statsColumns()}
                            defaultSortBy={isSeller ? 'winRate' : 'sellerCode'}
                            defaultSortByDirection={isSeller ? SORT.DESC : SORT.ASC}
                        />
                    </Preloader>
                </div>
                {
                    !fetchStatsRequestStatus &&
                    <div className="modal-footer">
                        <button className="btn btn-ghost" onClick={handleClose}>Close</button>
                    </div>
                }
            </Popup>
        );
    };

    const renderButton = () => {
        const isDisabled = !statsExist && !fetchStatsExistRequestState;

        return (
            <RoleActionBlocker roles={[...roles.seller(), roles.BrokerDealerTrader]}>
                {blocked =>
                    <OnHoverTooltip
                        overlay="You have no historical BWICs"
                        disabled={!isDisabled || blocked}
                    >
                        <FeatureButton className="btn-link" onClick={handleSetVisible} disabled={isDisabled}>
                            <IconSVG name="stats" width={16} height={16} />{title} Stats
                        </FeatureButton>
                    </OnHoverTooltip >
                }
            </RoleActionBlocker>
        );
    }

    return (
        <>
            {renderButton()}
            {renderPopup()}
        </>
    );
}
