import { onBoardingTooltipConst, roles } from '../../constants';
import { BwicSearchResult } from '../../types/bwic-monitor/BwicSearchResult';
import { ShowFor } from '../access';
import { Relative } from '../common';
import { OnBoardingTooltip } from '../onBoarding/OnBoardingTooltip';
import { BwicViewLink } from '../common/BwicViewLink';

interface Props {
    bwicIndex: number;
    bwic: BwicSearchResult;
}

export const AllBWICCustomButtons = ({ bwicIndex, bwic }: Props) => {
    return (
        <div className="display-inline" onClick={(e) => e.stopPropagation()}>
            <Relative>
                <BwicViewLink
                    small
                    referenceName={bwic.referenceName}
                    status={bwic.status}
                    isDirectBidding={bwic.isClearingBankParticipant}
                    isAllToAll={bwic.isAllToAll}
                    isMy={bwic.isMy}
                    isParticipant={bwic.isParticipant}
                    isParsed={bwic.isParsed}
                />
                <ShowFor roles={[...roles.seller(), ...roles.bd()]}>
                    {
                        bwicIndex === 0 &&
                        <OnBoardingTooltip
                            className="popover-onboarding-right"
                            page={onBoardingTooltipConst.pages.allBwics}
                            step={4}
                        />
                    }
                </ShowFor>
            </Relative>
        </div>
    );
}
