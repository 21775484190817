import { useEffect, useMemo } from 'react';
import { allBWICSActions } from '../../actions';
import { createFilterActions } from '../../actions/filter.actions';
import { RequestState } from '../../constants/request-state';
import { useAppSelector } from '../../effects/useAppSelector';
import { BwicFilterType } from '../../types/filters/FilterState';
import { PageConfigType } from '../../types/page-config/PageConfigType';
import { bwicMonitorBuyFilters, bwicMonitorFilters, bwicMonitorSellFilters } from '../../types/state/AllBwicsState';
import { FiltersState } from '../../types/state/FiltersState';
import { isRequestNone, isRequestSuccess, isRequesting } from '../../utils';
import { BwicFilters } from '../filters/BwicFilters';
import { AllBwicsTab } from './AllBwicsTab';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { AlertOption } from '../../types/email-preferences/EmailPreferences';
import { textLabels } from "../../constants/text-labels";

interface Props {
    tab: AllBwicsTab;
    disabled?: boolean;
    myBwicsExist: boolean;
    requestStateIsMyBwicsExist: RequestState;
}

export function getFilterType(tab: AllBwicsTab) {
    switch (tab) {
        case AllBwicsTab.Buy:
            return BwicFilterType.BwicMonitorBuy;
        case AllBwicsTab.Sell:
            return BwicFilterType.BwicMonitorSell;
        default:
            return BwicFilterType.BwicMonitor;
    }
}

function getState(tab: AllBwicsTab, state: FiltersState) {
    switch (tab) {
        case AllBwicsTab.Buy:
            return state.bwicMonitorBuy;
        case AllBwicsTab.Sell:
            return state.bwicMonitorSell;
        default:
            return state.bwicMonitor;
    }
}

function getAdditionalFilters(tab: AllBwicsTab) {
    switch (tab) {
        case AllBwicsTab.Buy:
            return bwicMonitorBuyFilters.additionalFilters;
        case AllBwicsTab.Sell:
            return bwicMonitorSellFilters.additionalFilters;
        default:
            return bwicMonitorFilters.additionalFilters;
    }
}

export const AllBwicsFilter = (
    { tab, disabled = false, myBwicsExist, requestStateIsMyBwicsExist }: Props
) => {
    const dispatch = useAppDispatch();

    const filterType = useMemo(() => getFilterType(tab), [tab]);
    const filterActions = useMemo(() => createFilterActions(filterType), [filterType]);

    const isFilterChanged = useAppSelector(s => getState(tab, s.filters).filterChanged);
    const isFilterModified = useAppSelector(s => getState(tab, s.filters).filterModified);
    const visibleFilters = useAppSelector(s => getState(tab, s.filters).visibleFilters);
    const filter = useAppSelector(s => getState(tab, s.filters).filter);
    const lastAppliedFilter = useAppSelector(s => getState(tab, s.filters).lastAppliedFilter);
    const searchInProgress = useAppSelector(s => s.allBWICS.searchInProgress);
    const isNextPageRequesting = useAppSelector(s => s.allBWICS.isNextPageRequesting);
    const selectedFilterReferenceName = useAppSelector(s => getState(tab, s.filters).selectedFilterReferenceName);

    useEffect(() => {
        if (isRequestSuccess(requestStateIsMyBwicsExist) && !myBwicsExist) {
            dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
        }
    }, [myBwicsExist, requestStateIsMyBwicsExist, tab, dispatch, filterActions]);

    return (
        <BwicFilters
            allowCollapsing={tab === AllBwicsTab.All}
            withAlerts={true}
            isSearching={
                disabled ||
                isRequestNone(searchInProgress) ||
                isRequesting(searchInProgress) ||
                isNextPageRequesting
            }
            visibleFilters={visibleFilters}
            hiddenFilters={getAdditionalFilters(tab)}
            filterType={filterType}
            lastAppliedFilter={lastAppliedFilter}
            filter={filter}
            filterChanged={isFilterChanged}
            filterModified={isFilterModified}
            withBlockedSearchAndFilter={disabled}
            saveFilterVisible={tab === AllBwicsTab.All}
            pageConfigType={PageConfigType.BwicMonitorAll}
            selectedFilterReferenceName={selectedFilterReferenceName}
            saveFilterBlockerFeature={SubscriptionFeature.BwicMonitorSavedFilters}
            advancedFiltersBlockerFeature={SubscriptionFeature.BwicMonitorAvancedFilters}
            filterAlertPopupTooltips={{
                [AlertOption.Daily]: textLabels.savedFiltersDefaultDailyTooltip,
                [AlertOption.Instant]: textLabels.savedFiltersDefaultInstantTooltip
            }}
            onVisibilityChange={key => dispatch(filterActions.filterVisibilityChange(key))}
            onApply={() => {
                dispatch(allBWICSActions.advancedFilterBlocked(false));
                dispatch(allBWICSActions.searchBwics(tab, true))
            }}
            onReset={() => {
                dispatch(filterActions.resetFiltersAndUnselectSavedFilter());
                dispatch(allBWICSActions.searchBwics(tab, true));
            }}
        />
    )
}
